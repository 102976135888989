<template>
  <div class="poll">
    <div class="poll-title">
      {{ polls.title_poll }}
    </div>
    <div 
      class="poll-answer"
      v-for="(item, index) in polls.answer"
      :key="index"
    >
      {{ item }}
    </div>
    <div v-show="pollComplete" class="poll-copmlete">
      <img src="@/assets/img/poll-vote.jpg" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      polls: {
        title_poll: "Нужен ли городу парк для выгула собак?",
        answer: ["нужна отдельная площадка", "нет, в городе достаточно парков", "затрудняюсь ответить"]
      },
      pollComplete: false
    }
  }
}
</script>

<style lang="scss" scoped>
.poll {
  height: 1150px;
  overflow-x: hidden;
  overflow-y: auto;
  background: linear-gradient(219.44deg, #01C39C -29.91%, #0B83FF 98.39%);
  text-align: center;
}
.poll-title {
  margin: 258px 122px 101px 122px;
  font-weight: 500;
  font-size: 60px;
}

.poll-answer {
  padding: 32px 62px;
  margin: 0 164px 32px 164px;
  font-size: 40px;
  font-weight: 500;
  border-radius: 99px;
  background-color: #37b5d7;
}
</style>